import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"

// Components
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHeroIh from "@components/staticHeroIh"
import Container from "@components/container"
import { sectionIh, headingIh, primaryCtaIh } from "@styles/ui"
import Section from "@components/section"
import AmbassadorQuote from "@components/AmbassadorQuote"
import AllSymptomsIh from "@components/AllSymptomsIh"
import PrimaryCta from "@components/PrimaryCta"
import InlineVideo from "@components/inlineVideo"
import SecondaryCallout from "@components/SecondaryCallout"
import { smallPurpleHeadingIh } from "@styles/ui"
import StayConnectedBannerIh from "@components/StayConnectedBannerIh"
import DownloadCalloutIh from "@components/DownloadCalloutIh"
import InlineVideoSectionIhRedesign from "@components/inlineVideoSectionIhRedesign"

const TalkingToYourDoctor = ({ location, data }) => {
  const downloads = [
    {
      title: (
        <>
          Doctor Discussion <br tw="hidden lg:block" /> Guide
        </>
      ),
      description:
        "Are you ready to talk to your doctor about XYWAV? Download this guide to take to your next visit.",
      pdf: "/pdf/50956_DDG_PDF_L07.pdf",
    },
    {
      title: (
        <>
          Idiopathic Hypersomnia <br tw="hidden lg:block" /> Severity Scale
          (IHSS)
        </>
      ),
      description:
        "The IHSS can help you rate the severity and frequency of your IH symptoms. Be sure to share the results with your doctor.",
      pdf: "/pdf/IHSS_Downloadable_PDF.pdf",
    },
    {
      title: "Epworth Sleepiness Scale (ESS)",
      description:
        "The ESS can help you find your level of daytime sleepiness. Be sure to share your results with your doctor.",
      pdf: "/pdf/ESS_Downloadable_PDF.pdf",
    },
  ]

  return (
    <Layout location={location}>
      <Seo
        title="Talking to Your Doctor About Idiopathic Hypersomnia | XYWAV® IH"
        description="Find downloadable tools like the Epworth Sleepiness Scale, Idiopathic Hypersomnia Severity Scale, and IH Doctor Discussion Guide to help facilitate your conversation with your doctor. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      {/* Static hero */}
      <StaticHeroIh
        heading={"Getting what you need from your doctor"}
        subheading="It's time to talk to your doctor about XYWAV"
      />

      <Section id="">
        <Container isIh>
          <h2 css={[headingIh, tw`pb-2 lg:max-w-[62rem] xl:(pb-5)`]}>
            Advocate for yourself so your doctor can make an informed decision
            about treating your Idiopathic Hypersomnia&nbsp;(IH).
          </h2>
          <div tw="flex flex-col lg:(gap-16 flex-row)">
            <div tw="text-center text-studio border border-studio rounded-[16px] font-bold p-12 text-2xl mb-5 lg:(basis-1/2 mb-0 max-w-[29.1875rem])">
              Communicating your experience <br tw="hidden lg:block" /> with IH
              can be challenging.{" "}
            </div>
            <div tw="lg:(basis-1/2 max-w-[31.9375rem])">
              <h3 tw="text-2xl font-bold mb-4">
                Doctor visits go by fast, and it can be tough to remember what
                you wanted to say.
              </h3>
              <p tw="text-base font-light">
                Talking with your doctor about your symptoms and how
                they&apos;re impacting you is essential to finding the treatment
                that may help you. Use the resources below to prepare for a
                successful discussion.
              </p>
            </div>
          </div>
        </Container>
      </Section>

      <Section addedStyles={sectionIh} bgColour={tw`bg-lilac`}>
        <Container isIh>
          <AmbassadorQuote
            ambassadorName="Emily"
            image={
              <StaticImage
                src="../../images/talk-to-your-doctor-ih/mask-photo.png"
                alt=""
                tw="w-[12.5rem] block mx-auto md:w-[19.625rem]"
              />
            }
            quote="When my doctor told me about XYWAV, she gave me a chance to ask questions. Learning more about it made me feel comfortable starting treatment."
            timeFootnote
          />
        </Container>
      </Section>

      <Section id="">
        <Container isIh>
          <h2 css={[headingIh, tw`pb-2 xl:(pb-5)`]}>
            Use these tools to get details and information to share with your
            doctor
          </h2>

          <div tw="flex gap-8 flex-col lg:flex-row">
            {downloads.map((download, i) => {
              return <DownloadCalloutIh download={download} key={i} />
            })}
          </div>
        </Container>
      </Section>

      <Section addedStyles={sectionIh} bgColour={tw`bg-lilac`}>
        <Container isIh>
          <h2 css={[headingIh, tw`lg:(mt-8)`]}>Talk about all your symptoms</h2>
          <p tw="mb-4 lg:(mb-16 max-w-[59.4375rem])">
            Your doctor may ask about your excessive daytime sleepiness. You
            should mention any other symptoms of IH you&apos;re experiencing,
            such as:
          </p>
          <AllSymptomsIh />

          <div tw="flex flex-col mt-16 lg:(flex-row mb-8 mt-24) justify-around items-center">
            <div tw="order-2 lg:order-1">
              <h3 tw="text-2xl font-bold mt-8 lg:mt-0">
                Do you have a provider?
              </h3>
              <p tw="text-base font-light my-6 lg:(max-w-[30.5rem])">
                If you&apos;re ready to take the next step in managing your IH,
                a doctor can help.
              </p>
              <PrimaryCta
                url="/idiopathic-hypersomnia/find-a-doctor/"
                addedStyles={[primaryCtaIh, tw`lg:(w-fit)`]}
              >
                Find A Provider
              </PrimaryCta>
            </div>
            <div tw="order-1 lg:order-2">
              <GatsbyImage image={getImage(data.map)} alt="" />
            </div>
          </div>
        </Container>
      </Section>

      <InlineVideoSectionIhRedesign
        isIh={true}
        bgColour={tw`bg-studio`}
        video={
          <InlineVideo
            wistiaId="zoj62xybuo"
            thumbnail={data.thumbImage}
            alt="Watch the video on tips for talking to your doctor about XYWAV®"
            inlineIh={true}
          />
        }
      >
        <h2 tw="text-xl text-white text-left font-ffcocon mb-4 lg:(text-[1.75rem] mb-6 max-w-[26rem])">
          Get tips from Diana on talking to your doctor about XYWAV
        </h2>
      </InlineVideoSectionIhRedesign>

      <Section addedStyles={sectionIh}>
        <Container isIh>
          <SecondaryCallout
            copy={
              <>
                <span css={[smallPurpleHeadingIh, tw`block !mb-0`]}>
                  Learn how individualized dosing with XYWAV may work for you
                </span>
              </>
            }
            cta="Learn More"
            url="/idiopathic-hypersomnia/dosing/"
          />
        </Container>
      </Section>
      <StayConnectedBannerIh />
    </Layout>
  )
}

export default TalkingToYourDoctor

export const query = graphql`
  query {
    map: file(relativePath: { eq: "ih/homepage/treatment_map.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, formats: [AUTO, WEBP, AVIF])
      }
    }
    thumbImage: file(
      relativePath: { eq: "ih/your-ih-experience/xywav-patient-diana-tips.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
